
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      fileHover: false,
    };
  },
})
export default class FileDrop extends Vue {
  fileHover!: boolean;

  onDrop(e: DragEvent) {
    this.fileHover = false;
    if (!e.dataTransfer) return;
    const { files } = e.dataTransfer;
    const fileArray = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files.item(i));
    }
    this.$emit("files", fileArray);
  }
}
