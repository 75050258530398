import api from "@/api";
import { User } from "@/models";
import { createStore } from "vuex";
import messages from "./messages";
import projects from "./projects";
import mixes from "./mixes";
import bands from "./bands";

export enum AuthState {
  Authenticating,
  NotLoggedIn,
  LoggedIn,
}

export interface RootState {
  authState: AuthState;
  mixTrackMenu: number;
  globalSelectDisable: boolean;
  user: User | null;
}

export default createStore<RootState>({
  state: {
    authState: AuthState.Authenticating,
    mixTrackMenu: 0,
    globalSelectDisable: false,
    user: null,
  },
  getters: {},
  mutations: {
    setUser(state, user: User) {
      state.user = user;
    },
    setAuthState(state, authState: AuthState) {
      state.authState = authState;
    },
    setGlobalSelectDisable(state, value: boolean) {
      state.globalSelectDisable = value;
    },
  },
  actions: {
    loadAuthInfo({ commit }) {
      return api.account
        .me()
        .then((user) => {
          commit("setUser", user);
          commit("setAuthState", AuthState.LoggedIn);
        })
        .catch((err) => {
          if (err.response?.status === 401) {
            commit("setAuthState", AuthState.NotLoggedIn);
          }
        });
    },
    logout({ commit }) {
      return api.account.logout().then(() => {
        commit("setUser", null);
        commit("setAuthState", AuthState.NotLoggedIn);
      });
    },
  },
  modules: { messages, projects, mixes, bands },
});
