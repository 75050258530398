
import { DOUBLE_CLICK_MAX_DELAY } from "@/misc";
import { Options, Vue } from "vue-class-component";
import { mapMutations } from "vuex";

@Options({
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: -1,
    },
    max: {
      type: Number,
      default: 1,
    },
    reset: {
      type: Number,
      default: 0,
    },
    factor: {
      type: Number,
      default: 0.01,
    },
    isWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      x: 0,
      clickedOnce: false,
      CENTER: 10,
      RADIUS: 8,
      SIZE: 20,
    };
  },
  methods: {
    ...mapMutations(["setGlobalSelectDisable"]),
  },
})
export default class Knob extends Vue {
  value!: number;
  min!: number;
  max!: number;
  reset!: number;
  factor!: number;
  isWidth!: number;
  x!: number;
  clickedOnce!: boolean;

  readonly CENTER!: number;
  readonly RADIUS!: number;
  readonly SIZE!: number;

  setGlobalSelectDisable!: (value: boolean) => void;

  click() {
    if (!this.clickedOnce) {
      this.clickedOnce = true;
      setTimeout(() => {
        this.clickedOnce = false;
      }, DOUBLE_CLICK_MAX_DELAY);
    } else {
      this.clickedOnce = false;
      this.dblclick();
    }
  }
  dblclick() {
    this.$emit("input", this.reset);
  }

  capture(e: MouseEvent) {
    this.x = e.clientX;
    this.setGlobalSelectDisable(true);
    window.addEventListener("mouseup", this.release);
    window.addEventListener("mousemove", this.onMouseMove);
  }

  release() {
    this.setGlobalSelectDisable(false);
    window.removeEventListener("mouseup", this.release);
    window.removeEventListener("mousemove", this.onMouseMove);
  }

  onMouseMove(e: MouseEvent) {
    const deltaX = e.clientX - this.x;
    this.x = e.clientX;
    let value = this.value + deltaX * this.factor;
    if (value < this.min) value = this.min;
    if (value > this.max) value = this.max;
    this.$emit("input", value);
  }

  private degToRad(angleDeg: number): number {
    return (angleDeg * Math.PI) / 180.0;
  }

  private angleToCoords(angleDeg: number): { x: number; y: number } {
    const angleRad = this.degToRad(angleDeg - 90);
    return {
      x: this.CENTER + this.RADIUS * Math.cos(angleRad),
      y: this.CENTER + this.RADIUS * Math.sin(angleRad),
    };
  }

  get widthPath() {
    const range = this.max - this.min;
    const prc = (this.value - this.min) / range;
    const halfAngle = 85 * prc;
    const largeArc = halfAngle > 90 ? 1 : 0;
    const angleDiff = this.degToRad(halfAngle * 2);
    const pointL = this.angleToCoords(-halfAngle);
    const pointR = this.angleToCoords(halfAngle);
    let path = `M ${this.CENTER} ${this.CENTER}`;
    path += `L ${pointL.x} ${pointL.y}`;
    path += `A ${this.RADIUS} ${this.RADIUS} ${angleDiff} ${largeArc} 1 ${pointR.x} ${pointR.y} Z`;
    return path;
  }

  get panPath() {
    const range = this.max - this.min;
    const prc = (this.value - this.min) / range;
    const angle = 270 * prc - 135;
    const point = this.angleToCoords(angle);
    return `M ${this.CENTER} ${this.CENTER} L ${point.x} ${point.y}`;
  }
}
