import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "track-name" }
const _hoisted_2 = { class: "track-hash" }
const _hoisted_3 = { class: "track-tag" }
const _hoisted_4 = { class: "track-del" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tag_picker = _resolveComponent("tag-picker")!
  const _component_trash_icon = _resolveComponent("trash-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "project-view-track-list-item",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.track.name), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.track.filehash), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tag_picker, {
        tag: _ctx.tag,
        onPick: _ctx.onPickTag
      }, null, 8, ["tag", "onPick"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('delete', _ctx.track)), ["prevent","stop"]))
      }, [
        _createVNode(_component_trash_icon)
      ])
    ])
  ], 4))
}