
import { Band } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import Autocomplete from "./Autocomplete.vue";

@Options({
  components: {
    Autocomplete,
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    ...mapState("bands", ["bands"]),
  },
})
export default class BandsAutocomplete extends Vue {
  options!: Band[];
  bands!: Band[];

  pick(band: Band) {
    this.$emit("pick", band);
    this.options = [];
  }

  loadOptions(value: string) {
    value = value.toLowerCase();
    this.options = this.bands.filter((band) => {
      return band.name.toLowerCase().includes(value);
    });
  }

  onInput(value: string) {
    this.loadOptions(value);
  }
}
