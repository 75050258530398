import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "track-picker-section" }
const _hoisted_2 = {
  key: 0,
  class: "track-list"
}
const _hoisted_3 = { class: "track-list-item-name" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!
  const _component_chevron_right_icon = _resolveComponent("chevron-right-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
      class: "track-picker-section-header",
      style: _normalizeStyle(_ctx.headerStyle)
    }, [
      (_ctx.open)
        ? (_openBlock(), _createBlock(_component_chevron_down_icon, { key: 0 }))
        : (_openBlock(), _createBlock(_component_chevron_right_icon, { key: 1 })),
      _createTextVNode(" " + _toDisplayString(_ctx.tag.name), 1)
    ], 4),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tracks, (track) => {
            return (_openBlock(), _createElementBlock("div", {
              key: track._id,
              class: "track-list-item"
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(track.name), 1),
              _createElementVNode("a", {
                href: "#",
                onClick: _withModifiers(($event: any) => (_ctx.addTrack(track)), ["prevent","stop"]),
                class: "track-list-item-add"
              }, [
                _createVNode(_component_plus_icon)
              ], 8, _hoisted_4)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}