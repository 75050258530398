import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "project-manager-mix" }
const _hoisted_2 = { class: "mix-version" }
const _hoisted_3 = { class: "mix-author" }
const _hoisted_4 = { class: "mix-updated" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: `/mixes/${_ctx.mix._id}`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.project.name) + " mix v" + _toDisplayString(_ctx.mix.version), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, " Submitted by " + _toDisplayString(_ctx.mix.author.first_name) + " " + _toDisplayString(_ctx.mix.author.last_name), 1),
    _createElementVNode("div", _hoisted_4, "updated " + _toDisplayString(_ctx.when), 1)
  ]))
}