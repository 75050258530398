import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-option" }
const _hoisted_2 = { class: "user-option-name" }
const _hoisted_3 = { class: "user-option-username" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_autocomplete = _resolveComponent("autocomplete")!

  return (_openBlock(), _createBlock(_component_autocomplete, {
    onPick: _ctx.pick,
    onInput: _ctx.onInput,
    onClear: _cache[0] || (_cache[0] = ($event: any) => (_ctx.options = [])),
    "fill-on-pick": true,
    options: _ctx.options,
    class: "users-autocomplete"
  }, {
    default: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(slotProps.option.first_name) + " " + _toDisplayString(slotProps.option.last_name), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(slotProps.option.username), 1)
      ])
    ]),
    _: 1
  }, 8, ["onPick", "onInput", "options"]))
}