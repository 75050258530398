
import { Message } from "@/store/messages";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import AlertBoxItem from "./AlertBoxItem.vue";

@Options({
  components: {
    AlertBoxItem,
  },
  computed: {
    ...mapState("messages", ["messages"]),
  },
})
export default class AlertBox extends Vue {
  messages!: Message[];
}
