
import { Options, Vue } from "vue-class-component";
import {
  PlayIcon,
  PauseIcon,
  BackwardIcon,
  ClockIcon,
  PlusIcon,
} from "@heroicons/vue/24/solid";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { formatTime } from "@/misc";
import { Mix, Project, Track } from "@/models";
import TrackPicker from "./TrackPicker.vue";
import { TimelineType } from "@/views/MixView.vue";

@Options({
  components: {
    PlayIcon,
    PlusIcon,
    PauseIcon,
    BackwardIcon,
    ClockIcon,
    ExclamationTriangleIcon,
    TrackPicker,
  },
  props: {
    timelineType: {
      type: String,
      default: "time",
    },
  },
  computed: {
    ...mapState("mixes", [
      "isPlaying",
      "isDecoding",
      "position",
      "mix",
      "mixPropsDirty",
    ]),
    ...mapState("projects", { project: "current" }),
    ...mapGetters("projects", ["tracksNotInUse"]),
  },
  methods: {
    ...mapActions("mixes", ["toggle", "stop", "play", "patchMix"]),
    ...mapMutations("mixes", ["setPosition"]),
  },
  data() {
    return {
      showTrackPicker: false,
    };
  },
})
export default class MixControl extends Vue {
  isPlaying!: boolean;
  isDecoding!: boolean;
  position!: number;
  mix!: Mix;
  project!: Project;
  tracksNotInUse!: Track[];
  showTrackPicker!: boolean;
  mixPropsDirty!: boolean;
  timelineType!: TimelineType;

  toggle!: () => Promise<void>;
  play!: () => Promise<void>;
  stop!: () => Promise<void>;
  setPosition!: (value: number) => void;
  patchMix!: (payload: Partial<Mix>) => Promise<void>;

  get clock() {
    return formatTime(this.position / 1000);
  }

  async rewind() {
    let wasPlaying = this.isPlaying;
    if (wasPlaying) {
      await this.stop();
    }
    this.setPosition(0);
    this.$emit("rewind");
    if (wasPlaying) {
      await this.play();
    }
  }

  onTempoChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const tempo = target.valueAsNumber;
    this.patchMix({ tempo });
  }

  onDescriptionChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const description = target.value;
    this.patchMix({ description });
  }

  mounted() {
    window.addEventListener("mousedown", this.onMouseDown);
  }

  beforeUnmount() {
    window.removeEventListener("mousedown", this.onMouseDown);
  }

  onMouseDown(e: MouseEvent) {
    if (this.showTrackPicker) {
      let node: HTMLElement | null = e.target as HTMLElement;
      const trackPicker = this.$refs.trackPicker as HTMLElement;
      while (node) {
        if (node === trackPicker) {
          return;
        }
        node = node.parentElement;
      }
      this.showTrackPicker = false;
    }
  }

  onAddTrack(track: Track) {
    this.$store.dispatch("mixes/createMixTrack", track._id);
  }

  onAddAllTracks() {
    this.tracksNotInUse.forEach((track) => {
      this.$store.dispatch("mixes/createMixTrack", track._id);
    });
    this.showTrackPicker = false;
  }
}
