export const WAVEFORM_SAMPLERATE = 44100;
export const WAVEFORM_SEC_PER_PIXEL = 0.03;
export const WAVEFORM_PIXEL_PER_SEC = 1 / WAVEFORM_SEC_PER_PIXEL;

export function formatTime(sec: number): string {
  let mprt = sec - Math.floor(sec);
  mprt = Math.floor(100 * mprt);
  sec = Math.floor(sec);
  let mins = Math.floor(sec / 60).toString();
  let secs = (sec % 60).toString();
  if (mins.length < 2) {
    mins = "0" + mins;
  }
  if (secs.length < 2) {
    secs = "0" + secs;
  }
  let mprts = mprt.toString();
  if (mprts.length < 2) {
    mprts = "0" + mprts;
  }
  return `${mins}:${secs}.${mprts}`;
}

export function timeDelta(dt: Date) {
  let delta = Math.round((new Date().getTime() - dt.getTime()) / 1000);
  if (delta < 60) {
    return "less than 1 minute ago";
  }
  delta = Math.round(delta / 60);
  if (delta < 60) {
    return `${delta} minutes ago`;
  }
  delta = Math.round(delta / 60);
  if (delta < 24) {
    return `${delta} hours ago`;
  }
  delta = Math.round(delta / 24);
  if (delta < 30) {
    return `${delta} days ago`;
  }
  return "more than a month ago";
}

export const DOUBLE_CLICK_MAX_DELAY = 300;
