import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`alert alert-${_ctx.message.type}`)
  }, [
    _createTextVNode(_toDisplayString(_ctx.message.text) + " ", 1),
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)), ["prevent","stop"])),
      class: "close"
    }, "×")
  ], 2))
}