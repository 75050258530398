
import { Options, Vue } from "vue-class-component";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";

@Options({
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      open: false,
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      default: "_id",
    },
    labelField: {
      type: String,
      default: "name",
    },
    placeholder: {
      type: String,
      default: "Pick an option",
    },
  },
})
export default class Picker extends Vue {
  // eslint-disable-next-line
  value!: Record<string, any> | null;
  // eslint-disable-next-line
  options!: Record<string, any>[];
  keyField!: string;
  labelField!: string;
  placeholder!: string;
  open!: boolean;

  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  }

  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(e: MouseEvent) {
    const picker = this.$refs.picker as HTMLElement;
    let node = e.target as HTMLElement | null;
    while (node) {
      if (node === picker) {
        return;
      }
      node = node.parentElement;
    }
    this.open = false;
  }

  get pickedLabel() {
    if (!this.value) return this.placeholder;
    return this.value[this.labelField];
  }

  // eslint-disable-next-line
  pick(option: Record<string, any>) {
    this.$emit("pick", option);
    this.open = false;
  }
}
