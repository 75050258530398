import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["cx", "cy", "r"]
const _hoisted_3 = ["d"]
const _hoisted_4 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "knob",
    onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.capture && _ctx.capture(...args))),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    (_openBlock(), _createElementBlock("svg", {
      width: "20",
      height: "20",
      stroke: "#999",
      "stroke-linejoin": "round",
      fill: "none",
      viewBox: `0 0 ${_ctx.SIZE} ${_ctx.SIZE}`
    }, [
      _createElementVNode("circle", {
        cx: _ctx.CENTER,
        cy: _ctx.CENTER,
        r: _ctx.RADIUS
      }, null, 8, _hoisted_2),
      (_ctx.isWidth)
        ? (_openBlock(), _createElementBlock("path", {
            key: 0,
            fill: "#e9ffd580",
            d: _ctx.widthPath
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("path", {
            key: 1,
            d: _ctx.panPath
          }, null, 8, _hoisted_4))
    ], 8, _hoisted_1))
  ], 32))
}