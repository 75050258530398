
import { Options, Vue } from "vue-class-component";
import _ from "lodash";

@Options({
  data() {
    return {
      value: "",
      idx: 0,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionKey: {
      type: String,
      default: "_id",
    },
    optionValue: {
      type: String,
      default: "name",
    },
    clearOnPick: {
      type: Boolean,
      default: false,
    },
    fillOnPick: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 250,
    },
  },
})
export default class Autocomplete extends Vue {
  value!: string;
  idx!: number;

  placeholder!: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options!: Record<string, any>[];
  optionKey!: string;
  optionValue!: string;
  delay!: number;
  clearOnPick!: boolean;
  fillOnPick!: boolean;

  emitInput!: (value: string) => void;

  created() {
    this.emitInput = _.throttle(this._emitInput, this.delay);
  }

  _emitInput(value: string) {
    this.$emit("input", value);
  }

  pick() {
    const option = this.options[this.idx];
    if (option) {
      this.$emit("pick", option);
      if (this.fillOnPick) {
        this.value = option[this.optionValue];
      } else if (this.clearOnPick) {
        this.value = "";
      }
    }
  }

  onInput(e: Event) {
    const target = e.target as HTMLInputElement;
    this.value = target.value;
    this.emitInput(this.value);
  }

  onBlur() {
    setTimeout(() => this.$emit("clear"), this.delay);
  }

  onKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        if (this.idx < this.options.length - 1) {
          this.idx++;
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        if (this.idx > 0) {
          this.idx--;
        }
        break;
      case "Enter":
        e.preventDefault();
        this.pick();
    }
  }
}
