
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    numBlocks: {
      type: Number,
      default: 40,
    },
    delay: {
      type: Number,
      default: 20,
    },
  },
})
export default class Loading extends Vue {
  numBlocks!: number;
  delay!: number;

  get blocks() {
    const blocks = [];
    for (let id = 0; id < this.numBlocks; id++) {
      let opacity = 1.0;
      if (id <= 3 || id > this.numBlocks - 4) {
        const distance = Math.min(id, this.numBlocks - 1 - id);
        switch (distance) {
          case 0:
            opacity = 0.3;
            break;
          case 1:
            opacity = 0.6;
            break;
          case 2:
            opacity = 0.85;
        }
      }
      const delay = (this.numBlocks - 1 - id) * this.delay;
      blocks.push({ id, delay, opacity });
    }
    return blocks;
  }
}
