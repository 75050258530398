
import api from "@/api";
import { User } from "@/models";
import { Options, Vue } from "vue-class-component";
import Autocomplete from "./Autocomplete.vue";

@Options({
  components: {
    Autocomplete,
  },
  data() {
    return {
      options: [],
    };
  },
})
export default class UsersAutocomplete extends Vue {
  options!: User[];
  users!: User[];

  pick(user: User) {
    this.$emit("pick", user);
    this.options = [];
  }

  async loadOptions(value: string) {
    value = value.toLowerCase();
    this.options = await api.users.search(value);
  }

  onInput(value: string) {
    this.loadOptions(value);
  }
}
