
import { MixTrackExt } from "@/store/mixes";
import { Options, Vue } from "vue-class-component";
import TrackMixer from "@/components/TrackMixer.vue";
import { mapActions, mapState } from "vuex";

let tm: number | null = null;
const COOLDOWN = 150;

@Options({
  components: {
    TrackMixer,
  },
  computed: {
    ...mapState("mixes", ["trackHeight"]),
  },
  props: {
    height: {
      type: Number,
      required: true,
    },
    mixTracks: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapActions("mixes", ["sortMixTracks", "deleteMixTrack"]),
  },
  data() {
    return {
      draggingId: null,
      dragY: 0,
      dragX: 0,
      dragOffsetY: 0,
      mouseY: 0,
      mouseX: 0,
    };
  },
})
export default class TrackSorter extends Vue {
  height!: number;
  mixTracks!: MixTrackExt[];
  draggingId!: string | null;
  dragY!: number;
  dragX!: number;
  dragOffsetY!: number;
  trackHeight!: number;
  mouseY!: number;
  mouseX!: number;

  sortMixTracks!: (payload: {
    dragId: string;
    dropId: string;
  }) => Promise<void>;

  deleteMixTrack!: (trackId: string) => Promise<void>;

  onInitialMouseDown(e: MouseEvent, id: string) {
    if (e.button !== 0) return;
    window.addEventListener("mouseup", this.onCoolDownMouseUp);
    tm = setTimeout(() => {
      window.removeEventListener("mouseup", this.onCoolDownMouseUp);
      this.onLongMouseDown(e, id);
    }, COOLDOWN);
  }

  onCoolDownMouseUp() {
    if (tm) {
      window.removeEventListener("mouseup", this.onCoolDownMouseUp);
      clearTimeout(tm);
      tm = null;
    }
  }

  onLongMouseDown(e: MouseEvent, id: string) {
    let node = e.target as HTMLElement | null;
    let meterFound = false;
    while (node) {
      if (node.classList.contains("meter")) {
        meterFound = true;
      } else if (node.classList.contains("track-mixer")) {
        if (meterFound) {
          this.draggingId = id;
          const rect = node.getBoundingClientRect();
          this.dragY = rect.y;
          this.dragX = rect.x;
          this.dragOffsetY =
            node.offsetTop - (node.parentElement?.offsetTop || 0);
          this.mouseY = e.screenY;
          this.mouseX = e.screenX;
          window.addEventListener("mousemove", this.onMouseMove);
          window.addEventListener("mouseup", this.onMouseUp);
        }
        return;
      }
      node = node.parentNode as HTMLElement | null;
    }
  }

  onMouseMove(e: MouseEvent) {
    const deltaY = e.screenY - this.mouseY;
    const deltaX = e.screenX - this.mouseX;
    this.mouseY = e.screenY;
    this.mouseX = e.screenX;
    this.dragY += deltaY;
    this.dragX += deltaX;
    this.dragOffsetY += deltaY;
  }

  async onMouseUp() {
    if (this.draggingId) {
      if (this.deleteTrack) {
        await this.deleteMixTrack(this.draggingId);
      } else if (this.dropId !== "") {
        await this.sortMixTracks({
          dragId: this.draggingId,
          dropId: this.dropId,
        });
      }
      this.draggingId = null;
    }
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  }

  get dragStyle() {
    if (!this.draggingId) {
      return {};
    }
    return { top: `${this.dragY}px`, left: `${this.dragX}px` };
  }

  get dropId(): string {
    if (this.draggingId === null) return "";

    if (this.dragX > 300) {
      return "";
    }
    if (this.dragOffsetY < 0) {
      return "at_start";
    }

    const offset = this.dragOffsetY;
    let dropIdx = Math.floor(offset / this.trackHeight);
    if (dropIdx >= this.mixTracks.length - 1) {
      dropIdx = this.mixTracks.length - 2;
    }

    let i = 0;
    const dropMixTrack = this.mixTracks.find((mt) => {
      if (mt._id === this.draggingId) return false;
      if (i === dropIdx) return true;
      i++;
    });
    return dropMixTrack?._id || "";
  }

  get deleteTrack(): boolean {
    return this.draggingId !== null && this.dragX > 300;
  }
}
