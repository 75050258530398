
import { ProgressTrack } from "@/store/projects";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
})
export default class ProgressTrackListItem extends Vue {
  track!: ProgressTrack;

  get progStyle() {
    return {
      width: `${this.track.progress}%`,
    };
  }
}
