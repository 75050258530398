import api from "@/api";
import { Mix, MixTrack, Track } from "@/models";
import { Module } from "vuex";
import { RootState } from ".";

const MIX_TRACK_STORABLE_KEYS: Set<keyof MixTrack> = new Set([
  "volume",
  "pan",
  "solo",
  "muted",
  "offset",
  "width",
  "order",
]);

let ac: AudioContext | null = null;
let meterInterval = -1;

function getAudioContext(): AudioContext {
  if (ac === null) {
    ac = new AudioContext();
  }
  return ac;
}

function setupMixTrackExt(mt: MixTrack): MixTrackExt {
  const mte: MixTrackExt = {
    ...mt,
    actualMute: false,
    channel: null,
    response: null,
  };
  return mte;
}

export type MixViewStatus =
  | "Idle"
  | "Playing"
  | "Decoding Audio"
  | "Saving mix";

export interface State {
  mix: Mix | null;
  mixTrackMap: Record<string, MixTrackExt>;
  mixPropsDirty: boolean;
  position: number;
  startedAt: number;
  trackHeight: number;
  isPlaying: boolean;
  isDecoding: boolean;
  channelsPlaying: number;
  status: MixViewStatus;
}

interface Channel {
  meter: number;
  gain: GainNode;
  panner: StereoPannerNode;
  widthLeft: StereoPannerNode;
  widthRight: StereoPannerNode;
  analyser: AnalyserNode;
  merger: ChannelMergerNode | null;
  splitter: ChannelSplitterNode | null;
  source: AudioBufferSourceNode | null;
  buffer: AudioBuffer;
  started: boolean;
  onEnded: (() => void) | null;
}

export interface MixTrackExt extends MixTrack {
  actualMute: boolean;
  channel: Channel | null;
  response: ArrayBuffer | null;
}

function mixTrackApplyChannelProps(mt: MixTrackExt) {
  if (mt.channel) {
    mt.channel.gain.gain.value = mt.actualMute ? 0 : mt.volume;
    mt.channel.panner.pan.value = mt.pan;
    mt.channel.widthLeft.pan.value = -mt.width;
    mt.channel.widthRight.pan.value = mt.width;
  }
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    mix: null,
    mixTrackMap: {},
    mixPropsDirty: false,
    channelsPlaying: 0,
    position: 0,
    startedAt: 0,
    isPlaying: false,
    isDecoding: false,
    trackHeight: 50,
    status: "Idle",
  },
  getters: {
    mixTracks(state) {
      const mts = Object.values(state.mixTrackMap);
      return mts.sort((a, b) => {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
    },
    mixTracksNotConfigured(_, getters) {
      return getters.mixTracks.filter((mt: MixTrackExt) => mt.channel === null);
    },
    hasNotConfiguredMixTracks(_, getters) {
      return getters.mixTracks.some((mt: MixTrackExt) => mt.channel === null);
    },
  },
  mutations: {
    resetChannelsPlaying(state) {
      state.channelsPlaying = 0;
    },
    incChannelsPlaying(state) {
      state.channelsPlaying++;
    },
    decChannelsPlaying(state) {
      state.channelsPlaying--;
    },
    setMix(state, mix: Mix | null) {
      state.mix = mix;
    },
    setPosition(state, position: number) {
      state.position = position;
    },
    setMixTracks(state, mixTracks: MixTrackExt[]) {
      state.mixTrackMap = mixTracks.reduce<Record<string, MixTrackExt>>(
        (acc, item) => {
          acc[item._id] = item;
          return acc;
        },
        {}
      );
    },
    setMixPropsDirty(state, value: boolean) {
      state.mixPropsDirty = value;
    },
    setPlaying(state, value: boolean) {
      state.isPlaying = value;
    },
    updatePosition(state) {
      const now = new Date().getTime();
      state.position = now - state.startedAt;
    },
    patchMixTrack(
      state,
      data: { mixTrackId: string; payload: Partial<MixTrackExt> }
    ) {
      const { mixTrackId, payload } = data;
      const mixTrack = state.mixTrackMap[mixTrackId];
      if (mixTrack) {
        state.mixTrackMap[mixTrackId] = {
          ...mixTrack,
          ...payload,
        };
      }
    },
    setChannelSource(
      state,
      data: { mixTrackId: string; source: AudioBufferSourceNode }
    ) {
      const { mixTrackId, source } = data;
      const mixTrack = state.mixTrackMap[mixTrackId];
      if (!mixTrack.channel) {
        throw Error("mixTrack channel is null, runtime error");
      }
      mixTrack.channel.source = source;
    },
    adjustChannel(state, mixTrackId) {
      const mt = state.mixTrackMap[mixTrackId];
      mixTrackApplyChannelProps(mt);
    },
    adjustChannels(state) {
      Object.values(state.mixTrackMap).forEach(mixTrackApplyChannelProps);
    },
    setStartedAt(state) {
      state.startedAt = new Date().getTime() - state.position;
    },
    setChannelMeter(state, data: { mixTrackId: string; meter: number }) {
      const { mixTrackId, meter } = data;
      const mixTrack = state.mixTrackMap[mixTrackId];
      if (!mixTrack.channel) {
        throw Error("mixTrack channel is null, runtime error");
      }
      mixTrack.channel.meter = meter;
    },
    setDecoding(state, value: boolean) {
      state.isDecoding = value;
    },
    setStatus(state, status: MixViewStatus) {
      state.status = status;
    },
    patchMix(state, payload: Partial<Mix>) {
      if (!state.mix) return;
      state.mix = {
        ...state.mix,
        ...payload,
      };
    },
    addMixTrack(state, mt: MixTrack) {
      if (!state.mix)
        throw Error("Runtime Error: can't add mixtrack, mix is not loaded");
      state.mix.mix_tracks!.push(mt);
      state.mixTrackMap[mt._id] = setupMixTrackExt(mt);
    },
    removeMixTrack(state, mixTrackId: string) {
      if (!state.mix)
        throw Error("Runtime Error: can't remove mixtrack, mix is not loaded");
      state.mix.mix_tracks = state.mix.mix_tracks!.filter(
        (mt) => mt._id === mixTrackId
      );
      delete state.mixTrackMap[mixTrackId];
    },
  },
  actions: {
    sortMixTracks(
      { dispatch, getters },
      payload: { dragId: string; dropId: string }
    ) {
      const { dragId, dropId } = payload;
      const mixTracks: MixTrackExt[] = getters.mixTracks;
      const dragIdx = mixTracks.findIndex((mt) => mt._id === dragId);
      const dragMixTrack = mixTracks.splice(dragIdx, 1);
      const insertIdx =
        dropId === "at_start"
          ? 0
          : mixTracks.findIndex((mt) => mt._id === dropId) + 1;
      mixTracks.splice(insertIdx, 0, ...dragMixTrack);
      mixTracks.forEach((mt, idx) => {
        dispatch("patchMixTrack", {
          mixTrackId: mt._id,
          payload: {
            order: idx,
          },
        });
      });
    },
    resetMeters({ getters, commit }) {
      getters.mixTracks.forEach((mt: MixTrackExt) => {
        commit("setChannelMeter", {
          mixTrackId: mt._id,
          meter: 0,
        });
      });
    },
    updateMeters({ getters, commit }) {
      getters.mixTracks.forEach((mt: MixTrackExt) => {
        if (mt.channel) {
          const { analyser } = mt.channel;
          const data = new Float32Array(analyser.frequencyBinCount);
          analyser.getFloatFrequencyData(data);
          let value = Math.max(...data) + 120;
          if (value < 0) value = 0;
          if (value > 100) value = 100;
          commit("setChannelMeter", {
            mixTrackId: mt._id,
            meter: value,
          });
        }
      });
    },
    recalculate({ getters, commit }) {
      let needsAdjustment = false;
      const mixTracks = getters.mixTracks as MixTrackExt[];
      const mixHasSolo = mixTracks.some((mt: MixTrackExt) => mt.solo);
      mixTracks.forEach((mt) => {
        const actualMute = mixHasSolo ? !mt.solo : mt.muted;
        if (actualMute !== mt.actualMute) {
          needsAdjustment = true;
          commit("patchMixTrack", {
            mixTrackId: mt._id,
            payload: { actualMute },
          });
        }
      });
      if (needsAdjustment) {
        commit("adjustChannels");
      }
    },
    patchMixTrack(
      { commit, dispatch },
      data: { mixTrackId: string; payload: Partial<MixTrack> }
    ) {
      commit("patchMixTrack", data);
      const { mixTrackId, payload } = data;
      const keysModified = Object.keys(payload);

      const dirty = keysModified.some((key) =>
        MIX_TRACK_STORABLE_KEYS.has(key as keyof MixTrack)
      );

      // this expression is not equal to setMixPropsDirty(dirty)!
      // we don't want to fire mutations every frame
      if (dirty) {
        commit("setMixPropsDirty", true);
        commit("adjustChannel", mixTrackId);
      }
      dispatch("recalculate");
    },
    patchMix({ commit }, payload: Partial<Mix>) {
      commit("patchMix", payload);
      commit("setMixPropsDirty", true);
    },
    async loadMix({ commit, dispatch, rootGetters }, mixId) {
      const mix = await api.mixes.get(mixId);
      commit("setMix", mix);
      await this.dispatch("projects/loadCurrent", mix.project_id);

      const requests: Promise<ArrayBuffer>[] = [];
      const trackMap: Record<string, Track> = rootGetters["projects/trackMap"];
      const mixTracks = mix.mix_tracks!.map((mt) => {
        const track = trackMap[mt.track_id];
        requests.push(api.tracks.download(track));
        return setupMixTrackExt(mt);
      });
      const responses = await Promise.all(requests);
      responses.forEach((response, idx) => {
        mixTracks[idx].response = response;
      });
      commit("setMixTracks", mixTracks);
      commit("setMixPropsDirty", false);
      dispatch("recalculate");
      commit("setPosition", 0);
    },
    async setupChannel({ commit }, mixTrack: MixTrackExt) {
      const { response } = mixTrack;
      if (response === null)
        throw Error("mixTrack response is null, this is a runtime error");
      const ac = getAudioContext();
      const gain = ac.createGain();
      const panner = ac.createStereoPanner();
      const splitter = ac.createChannelSplitter(2);
      const widthLeft = ac.createStereoPanner();
      const widthRight = ac.createStereoPanner();
      const analyser = ac.createAnalyser();
      gain.gain.value = mixTrack.actualMute ? 0 : mixTrack.volume;
      panner.pan.value = mixTrack.pan;
      // gain.connect(panner).connect(analyser).connect(ac.destination);
      gain.connect(splitter);
      splitter.connect(widthLeft, 0).connect(panner);
      splitter.connect(widthRight, 1).connect(panner);
      panner.connect(analyser).connect(ac.destination);
      return new Promise((res: (value: void) => void) => {
        ac.decodeAudioData(response, (buffer) => {
          const channel: Channel = {
            gain,
            panner,
            analyser,
            meter: 0,
            splitter,
            widthLeft,
            widthRight,
            buffer,
            source: null,
            merger: null,
            started: false,
            onEnded: null,
          };
          commit("patchMixTrack", {
            mixTrackId: mixTrack._id,
            payload: {
              channel,
            },
          });
          res();
        });
      });
    },
    async setupAudio({ getters, commit, dispatch }) {
      const tasks: Promise<void>[] = [];
      getters.mixTracksNotConfigured.forEach((mt: MixTrackExt) => {
        tasks.push(dispatch("setupChannel", mt));
      });
      await Promise.all(tasks);
      commit("adjustChannels");
    },
    async startChannel({ state, commit, dispatch }, mixTrackId) {
      const ac = getAudioContext();
      commit("setChannelSource", {
        mixTrackId,
        source: ac.createBufferSource(),
      });
      const mixTrack = state.mixTrackMap[mixTrackId];
      const channel = mixTrack.channel;
      if (channel?.source) {
        let src: AudioBufferSourceNode | ChannelMergerNode | null =
          channel.source;
        if (channel.buffer.numberOfChannels === 1) {
          channel.merger = ac.createChannelMerger();
          channel.source.connect(channel.merger, 0, 0);
          channel.source.connect(channel.merger, 0, 1);
          src = channel.merger;
        }
        src.connect(channel.gain);
        channel.source.buffer = channel.buffer;
        channel.onEnded = () => {
          commit("decChannelsPlaying");
          if (mixTrack.channel) {
            mixTrack.channel.source!.removeEventListener(
              "ended",
              mixTrack.channel.onEnded!
            );
          }
          if (state.channelsPlaying === 0) {
            dispatch("stop", true);
          }
        };
        channel.source.addEventListener("ended", channel.onEnded!);
        channel.source.start(0, state.position / 1000);
        commit("incChannelsPlaying");
      }
    },
    stopChannel({ state }, mixTrackId) {
      const mixTrack = state.mixTrackMap[mixTrackId];
      if (mixTrack.channel?.source) {
        mixTrack.channel.source.removeEventListener(
          "ended",
          mixTrack.channel.onEnded!
        );
        mixTrack.channel.source.stop();
        mixTrack.channel.source.disconnect();
        mixTrack.channel.onEnded = null;
        if (mixTrack.channel.buffer.numberOfChannels === 0) {
          mixTrack.channel.merger!.disconnect();
          mixTrack.channel.merger = null;
        }
        mixTrack.channel.source = null;
      }
    },
    async play({ state, getters, commit, dispatch }) {
      const mtCount = Object.keys(state.mixTrackMap).length;
      if (mtCount === 0) return;

      if (getters.hasNotConfiguredMixTracks) {
        commit("setDecoding", true);
        commit("setStatus", "Decoding Audio");
        await dispatch("setupAudio");
        commit("setDecoding", false);
      }
      if (state.isDecoding) return;

      commit("setStartedAt");
      getters.mixTracks.forEach((mt: MixTrackExt) => {
        dispatch("startChannel", mt._id);
      });
      commit("setPlaying", true);
      commit("setStatus", "Playing");
      meterInterval = setInterval(() => {
        dispatch("updateMeters");
        commit("updatePosition");
      }, 20);
    },
    async stop({ getters, commit, dispatch }) {
      getters.mixTracks.forEach((mt: MixTrackExt) => {
        dispatch("stopChannel", mt._id);
      });
      commit("setPlaying", false);
      commit("setStatus", "Idle");
      commit("resetChannelsPlaying");
      clearInterval(meterInterval);
      meterInterval = -1;
      dispatch("resetMeters");
    },

    async toggle({ state, dispatch }) {
      return state.isPlaying ? dispatch("stop") : dispatch("play");
    },

    bounce(
      { state },
      options: { bitrate: string; samplerate: string; quality: string }
    ) {
      if (!state.mix) return;
      const mixId = state.mix._id;
      return api.mixes.bounce(mixId, options);
    },

    saveMix({ state, getters, commit }) {
      if (!state.mix) return;
      const mixId = state.mix._id;
      const mix: Partial<Mix> = { ...state.mix };
      delete mix.mix_tracks;
      delete mix.author;
      delete mix.project_id;

      const mixTracks: MixTrack[] = getters.mixTracks.map((mt: MixTrackExt) => {
        const payload = Array.from(MIX_TRACK_STORABLE_KEYS).reduce<
          Record<string, string | number | boolean>
        >((acc, item) => {
          acc[item] = mt[item];
          return acc;
        }, {});
        payload._id = mt._id;
        return payload as unknown as MixTrack;
      });

      commit("setStatus", "Saving mix");
      return api.mixes
        .update(mixId, mix)
        .then((mix) => {
          commit("setMix", mix);
          commit("setMixPropsDirty", false);
          return api.mixes.bulkUpdate(mix._id, mixTracks);
        })
        .finally(() => {
          commit("setStatus", "Idle");
        });
    },
    async cloneMix({ state, dispatch }) {
      if (!state.mix)
        throw Error("Runtime Error: can't clone! Mix is not loaded!");
      await dispatch("saveMix");
      const mixId = state.mix._id;
      return await api.mixes.clone(mixId);
    },
    deleteMix({ state }) {
      if (!state.mix)
        throw Error("Runtime Error: can't delete! Mix is not loaded!");
      return api.mixes.delete(state.mix._id);
    },
    async createMixTrack(
      { state, dispatch, commit, rootGetters },
      trackId: string
    ) {
      if (!state.mix)
        throw Error("Runtime Error: can't create mixTrack, mix is not loaded");

      const wasPlaying = state.isPlaying;
      if (state.isPlaying) {
        await dispatch("stop");
      }

      const mixTrack = await api.mixTracks.create(state.mix._id, trackId);
      commit("addMixTrack", mixTrack);
      const trackMap: Record<string, Track> = rootGetters["projects/trackMap"];
      const track = trackMap[mixTrack.track_id];
      const response = await api.tracks.download(track);
      commit("patchMixTrack", {
        mixTrackId: mixTrack._id,
        payload: { response },
      });
      const mte = state.mixTrackMap[mixTrack._id];
      if (ac !== null) {
        await dispatch("setupChannel", mte);
      }
      if (wasPlaying) {
        await dispatch("play");
      }
    },
    async deleteMixTrack({ state, dispatch, commit }, mixTrackId: string) {
      if (!state.mix)
        throw Error("Runtime Error: can't remove mixTrack, mix is not loaded");
      const mixId = state.mix._id;
      await dispatch("stopChannel", mixTrackId);
      commit("removeMixTrack", mixTrackId);
      return await api.mixTracks.delete(mixId, mixTrackId);
    },
  },
};

export default module;
