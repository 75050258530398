
import { Options, Vue } from "vue-class-component";
import Loading from "@/components/Loading.vue";
import { mapState } from "vuex";
import { Project, Track } from "@/models";
import FileDrop from "@/components/FileDrop.vue";
import TrackListItem from "@/components/TrackListItem.vue";
import ProgressTrackListItem from "@/components/ProgressTrackListItem.vue";
import { ProgressTrack } from "@/store/projects";
import Confirm from "@/components/Confirm.vue";

@Options({
  components: {
    Confirm,
    Loading,
    FileDrop,
    TrackListItem,
    ProgressTrackListItem,
  },
  data() {
    return {
      isLoading: true,
      deleteConfirm: false,
      name: "",
      tempo: 0,
    };
  },
  computed: {
    ...mapState("projects", {
      project: "current",
      progressTracks: "progressTracks",
    }),
  },
  watch: {
    "$route.params"() {
      if (this.$route.params.id) {
        this.reload();
      }
    },
  },
})
export default class ProjectView extends Vue {
  isLoading!: boolean;
  project!: Project;
  progressTracks!: ProgressTrack[];
  deleteConfirm!: boolean;

  name!: string;
  tempo!: number;

  reload() {
    this.isLoading = true;
    this.$store
      .dispatch("projects/loadCurrent", this.$route.params.id)
      .then(() => {
        this.pullProjectFields();
        this.isLoading = false;
        document.title = `${this.project.name} by ${this.project.band.name} - Eavesdrop`;
      });
  }

  private pullProjectFields() {
    this.name = this.project.name;
    this.tempo = this.project.tempo;
  }

  onDropFiles(files: File[]) {
    this.$store.dispatch("projects/uploadFiles", files);
  }

  onDeleteTrack(track: Track) {
    this.$store.dispatch("projects/deleteTrack", track);
  }

  mounted() {
    this.reload();
  }

  destroyProject() {
    this.$store
      .dispatch("projects/destroyProject", this.project._id)
      .then(() => {
        this.$router.replace("/projects");
      });
  }

  saveSettings() {
    this.$store
      .dispatch("projects/updateProject", {
        name: this.name,
        tempo: this.tempo,
      })
      .then(() => {
        this.pullProjectFields();
      });
  }

  createMix() {
    this.$store.dispatch("projects/createMix").then((mix) => {
      this.$router.push(`/mixes/${mix._id}`);
    });
  }
}
