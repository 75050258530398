import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "track-timeline",
    style: _normalizeStyle({ width: `${_ctx.trackLengthPx}px`, top: `${_ctx.top}px` })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ticks, (tick) => {
      return (_openBlock(), _createElementBlock("div", {
        key: tick.label,
        class: "tick",
        style: _normalizeStyle({ left: `${tick.left}px` })
      }, _toDisplayString(tick.label), 5))
    }), 128))
  ], 4))
}