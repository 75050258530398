
import { Message } from "@/store/messages";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
})
export default class AlertBoxItem extends Vue {
  message!: Message;

  onClose() {
    this.$store.commit("messages/removeMessage", this.message.id);
  }
}
