import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "autocomplete" }
const _hoisted_2 = ["placeholder", "value"]
const _hoisted_3 = {
  key: 0,
  class: "autocomplete-options"
}
const _hoisted_4 = ["onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      ref: "input",
      type: "text",
      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitInput(_ctx.value))),
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      onInput: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)), ["stop"])),
      onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
      onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
      class: "input autocomplete-input"
    }, null, 40, _hoisted_2),
    (_ctx.options.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, optionIdx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["autocomplete-options-item", { 'autocomplete-options-item-active': _ctx.idx === optionIdx }]),
              onMouseenter: ($event: any) => (_ctx.idx = optionIdx),
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.pick && _ctx.pick(...args))),
              key: option[_ctx.optionKey]
            }, [
              _renderSlot(_ctx.$slots, "default", { option: option })
            ], 42, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}