
import Modal from "./Modal.vue";
import { Options, Vue } from "vue-class-component";
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";
import Picker from "./Picker.vue";

const bitrates = [
  "32",
  "40",
  "48",
  "56",
  "64",
  "80",
  "96",
  "112",
  "128",
  "160",
  "192",
  "224",
  "256",
  "320",
];

const samplerates = ["22050", "24000", "44100", "48000"];

@Options({
  components: {
    Modal,
    Picker,
    CloudArrowDownIcon,
  },
  data() {
    return {
      bitrate: null,
      samplerate: null,
      quality: 7,
    };
  },
})
export default class BounceModal extends Vue {
  bitrate!: { key: string; label: string } | null;
  samplerate!: { key: string; label: string } | null;
  quality!: number;

  get bitrates() {
    return bitrates.map((btr) => ({ key: btr, label: `${btr} kbps` }));
  }

  get samplerates() {
    return samplerates.map((item) => ({
      key: item,
      label: item + " hz",
    }));
  }

  created() {
    this.bitrate = this.bitrates[8];
    this.samplerate = this.samplerates[2];
  }

  onOk() {
    this.$store.dispatch("mixes/bounce", {
      bitrate: this.bitrate!.key,
      samplerate: this.samplerate!.key,
      quality: this.quality,
    });
    this.$emit("close");
  }

  onCancel() {
    this.$emit("close");
  }
}
