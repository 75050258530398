
import { Options, Vue } from "vue-class-component";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/vue/24/solid";
import { Tag, Track } from "@/models";

@Options({
  components: {
    ChevronRightIcon,
    ChevronDownIcon,
    PlusIcon,
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
    tracks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
})
export default class TrackPickerSection extends Vue {
  tag!: Tag;
  tracks!: Track[];
  open!: boolean;

  get headerStyle() {
    return {
      backgroundColor: `#${this.tag.color}50`,
    };
  }

  addTrack(track: Track) {
    this.$emit("add", track);
  }
}
