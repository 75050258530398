
import { timeDelta } from "@/misc";
import { Mix, Project } from "@/models";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    project: {
      type: Object,
      required: true,
    },
    mix: {
      type: Object,
      required: true,
    },
  },
})
export default class ProjectManagerMix extends Vue {
  mix!: Mix;
  project!: Project;

  get when() {
    const updated = new Date(this.mix.updated_at);
    return timeDelta(updated);
  }
}
