
import { Mix, Project, Track } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Loading from "../components/Loading.vue";
import TrackWaveform from "@/components/TrackWaveform.vue";
import TrackTimeline from "@/components/TrackTimeline.vue";
import TrackSorter from "@/components/TrackSorter.vue";
import TrackPositionPointer from "@/components/TrackPositionPointer.vue";
import MixControl from "@/components/MixControl.vue";
import { WAVEFORM_PIXEL_PER_SEC } from "@/misc";
import { MixTrackExt, MixViewStatus } from "@/store/mixes";

export type TimelineType = "bar" | "time";

@Options({
  components: {
    Loading,
    MixControl,
    TrackSorter,
    TrackWaveform,
    TrackTimeline,
    TrackPositionPointer,
  },
  data() {
    return {
      isLoading: true,
      isDragging: false,
      wasPlaying: false,
      timelineVerticalPos: 0,
      scrollPosition: 0,
      timelineType: "time",
    };
  },
  computed: {
    ...mapState("mixes", [
      "mix",
      "position",
      "isPlaying",
      "trackHeight",
      "status",
    ]),
    ...mapGetters("mixes", ["mixTracks"]),
    ...mapState("projects", { project: "current" }),
    ...mapGetters("projects", ["trackMap"]),
  },
  methods: {
    ...mapActions("mixes", ["play", "stop", "toggle"]),
    ...mapMutations("mixes", ["setPosition", "setStatus"]),
    ...mapMutations(["setGlobalSelectDisable"]),
  },
  watch: {
    position() {
      this.onPositionChange();
    },
    "$route.params"() {
      if (this.$route.params.id) {
        this.reload();
      }
    },
  },
})
export default class MixView extends Vue {
  isLoading!: boolean;
  isDragging!: boolean;
  wasPlaying!: boolean;
  timelineVerticalPos!: number;

  mix!: Mix;
  mixTracks!: MixTrackExt[];
  project!: Project;
  trackMap!: Record<string, Track>;
  position!: number;
  isPlaying!: boolean;
  trackHeight!: number;
  status!: MixViewStatus;
  scrollPosition!: number;
  timelineType!: TimelineType;

  toggle!: () => Promise<void>;
  stop!: () => Promise<void>;
  play!: () => Promise<void>;
  setPosition!: (pos: number) => void;
  setStatus!: (status: MixViewStatus) => void;
  setGlobalSelectDisable!: (value: boolean) => void;

  get blockHeight() {
    return this.trackHeight * this.mixTracks.length;
  }

  rewind() {
    const wfsection = this.$refs.wfsection as HTMLElement | null;
    if (wfsection) {
      wfsection.scrollLeft = 0;
    }
  }

  onPositionChange() {
    const wfsection = this.$refs.wfsection as HTMLElement | null;
    if (wfsection) {
      const pixpos = (this.position / 1000) * WAVEFORM_PIXEL_PER_SEC;
      let diff = pixpos - wfsection.scrollLeft - (wfsection.clientWidth - 80);
      if (diff > 0 && diff < 100) {
        // if diff > 0, track is automatically scrolled
        // if diff > 100, track was likely scrolled aggressively by user
        // and doesn't require autoscrolling anymore
        wfsection.scrollBy(diff, 0);
      } else {
        diff = pixpos - wfsection.scrollLeft;
        if (diff < 80 && diff > -20) {
          wfsection.scrollBy(-diff, 0);
        }
      }
    }
  }

  onScrollChange(e: Event) {
    const wfsection = e.target as HTMLElement;
    this.scrollPosition = wfsection.scrollLeft;
  }

  mounted() {
    this.reload();
  }

  async beforeUnmount() {
    this.removeKeyBindings();
    await this.stop();
  }

  reload() {
    const mixId = this.$route.params.id;
    this.isLoading = true;
    this.$store
      .dispatch("mixes/loadMix", mixId)
      .then(() => {
        document.title = `${this.project.name} mix v${this.mix.version} by ${this.project.band.name} - Eavesdrop`;
      })
      .catch((err) => {
        if (err.response?.status === 404) {
          this.$router.replace("/");
        } else {
          throw err;
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.setupKeyBindings();
      });
  }

  onKeyDown(e: KeyboardEvent) {
    const el = e.target as HTMLElement;
    if (el.tagName === "INPUT") {
      // when focused on an input, don't toggle the player
      return;
    }
    if (e.key === " ") {
      this.toggle();
      return false;
    }
  }

  onVerticalScroll(e: Event) {
    const el = e.target as HTMLElement;
    this.timelineVerticalPos = el.scrollTop;
  }

  setupKeyBindings() {
    window.addEventListener("keydown", this.onKeyDown);
  }

  removeKeyBindings() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onMouseUp() {
    this.isDragging = false;
    this.setGlobalSelectDisable(false);
    window.removeEventListener("mouseup", this.onMouseUp);
    if (this.wasPlaying) {
      this.play();
    }
  }

  onWFMouseDown(e: MouseEvent) {
    this.isDragging = true;
    this.setGlobalSelectDisable(true);
    window.addEventListener("mouseup", this.onMouseUp);
    this.wasPlaying = this.isPlaying;
    if (this.isPlaying) {
      this.stop();
    }
    this.setPositionFromMouseCoord(e);
  }

  onWFMouseMove(e: MouseEvent) {
    if (this.isDragging) {
      this.setPositionFromMouseCoord(e);
    }
  }

  setPositionFromMouseCoord(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (!target.classList.contains("track-waveform")) return;
    const x = e.offsetX;
    let position = Math.round((x / WAVEFORM_PIXEL_PER_SEC) * 1000);
    if (position < 0) {
      position = 0;
    }
    this.setPosition(position);
  }
}
