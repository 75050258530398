import { Module } from "vuex";
import { RootState } from ".";

export type MessageType = "error" | "info" | "warn" | "success";
export interface Message {
  id: number;
  type: MessageType;
  text: string;
}

export interface State {
  messages: Message[];
}

const MESSAGE_TTL = 8000;

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    messages: [],
  },
  mutations: {
    addMessage(state, message: Message) {
      state.messages.push(message);
    },
    removeMessage(state, id: number) {
      state.messages = state.messages.filter((msg) => msg.id !== id);
    },
  },
  actions: {
    addMessage(
      { commit },
      { type, text }: { type: MessageType; text: string }
    ) {
      const msg: Message = {
        id: new Date().getTime(),
        type,
        text,
      };
      commit("addMessage", msg);
      setTimeout(() => {
        commit("removeMessage", msg.id);
      }, MESSAGE_TTL);
    },
    alert({ dispatch }, text: string) {
      return dispatch("addMessage", { type: "error", text });
    },
    error({ dispatch }, text: string) {
      return dispatch("addMessage", { type: "error", text: `Error: ${text}` });
    },
    info({ dispatch }, text: string) {
      return dispatch("addMessage", { type: "info", text });
    },
    success({ dispatch }, text: string) {
      return dispatch("addMessage", { type: "success", text });
    },
    warn({ dispatch }, text: string) {
      return dispatch("addMessage", { type: "warn", text });
    },
  },
};

export default module;
