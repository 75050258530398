import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "loading-animation-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blocks, (block) => {
      return (_openBlock(), _createElementBlock("div", {
        key: block.id,
        class: "loading-block",
        style: _normalizeStyle({ animationDelay: `${block.delay}ms`, opacity: block.opacity })
      }, null, 4))
    }), 128)),
    _createElementVNode("h3", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}