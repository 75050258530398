
import { timeDelta } from "@/misc";
import { Project } from "@/models";
import { Options, Vue } from "vue-class-component";
import ProjectManagerMix from "./ProjectManagerMix.vue";
@Options({
  components: {
    ProjectManagerMix,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
})
export default class ProjectManagerProject extends Vue {
  project!: Project;

  get when() {
    const updated = new Date(this.project.updated_at);
    return timeDelta(updated);
  }
}
