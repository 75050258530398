import api from "@/api";
import { Mix, Project, Tag, Track } from "@/models";
import { Module } from "vuex";
import { RootState } from ".";
import { State as MixesState } from "./mixes";

export interface State {
  projects: Project[];
  progressTracks: ProgressTrack[];
  current: Project | null;
  tags: Record<string, Tag>;
}

function tracksSort(a: Track, b: Track) {
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
}

export interface ProgressTrack {
  name: string;
  progress: number;
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    projects: [],
    current: null,
    progressTracks: [],
    tags: {},
  },
  getters: {
    trackMap(state) {
      // generates (and hopefully caches) a map of string => Track from current project tracks
      return (
        state.current?.tracks.reduce<Record<string, Track>>(
          (acc, track) => ({
            ...acc,
            [track._id]: track,
          }),
          {}
        ) || {}
      );
    },
    tracksNotInUse(state, _, rootState) {
      if (!state.current)
        throw Error("Runtime Error: can't get tracks as project is not loaded");
      // Hack to get access to vuex modules
      // as they can't be typed in the RootState interface
      const { mixes } = rootState as unknown as { mixes: MixesState };
      const mixTracks = Object.values(mixes.mixTrackMap);
      return state.current.tracks.filter((track) => {
        return !mixTracks.some((mt) => mt.track_id === track._id);
      });
    },
    maxNumSamples(state) {
      const nsArray = state.current?.tracks.map(
        (track) => track.num_samples || 0
      );
      return nsArray ? Math.max(...nsArray) : 0;
    },
  },
  mutations: {
    setTrackTag(state, payload: { filehash: string; tagId: string }) {
      if (state.current) {
        state.current.tracks = state.current.tracks.map((track) => {
          if (track.filehash === payload.filehash) {
            track.tag_id = payload.tagId;
          }
          return track;
        });
      }
    },
    setProjects(state, projects: Project[]) {
      state.projects = projects;
    },
    setCurrent(state, project: Project | null) {
      if (project) {
        project.tracks = project.tracks.sort(tracksSort);
      }
      state.current = project;
    },
    addTracks(state, tracks: Track[]) {
      if (state.current) {
        tracks = [...state.current.tracks, ...tracks];
        tracks.sort(tracksSort);
        state.current.tracks = tracks;
      }
    },
    removeTrack(state, track: Track) {
      if (state.current) {
        state.current.tracks = state.current.tracks.filter(
          (t) => t.filehash !== track.filehash
        );
      }
    },
    updateProgressTrack(state, track: ProgressTrack) {
      let updated = false;
      state.progressTracks = state.progressTracks.map((ex) => {
        if (track.name === ex.name) {
          ex = {
            ...ex,
            ...track,
          };
          updated = true;
        }
        return ex;
      });
      if (!updated) {
        state.progressTracks.push(track);
      }
    },
    setTags(state, tagsList: Tag[]) {
      state.tags = tagsList.reduce<Record<string, Tag>>((acc, item) => {
        acc[item._id] = item;
        return acc;
      }, {});
    },
    addMixToProject(state, mix: Mix) {
      if (!state.current)
        throw Error("Runtime Error: project is not loaded, can't create mix");
      state.current.mixes.push(mix);
    },
    updateProgressByFilename(state, payload: ProgressTrack) {
      const track = state.progressTracks.find((pt) => pt.name === payload.name);
      if (track) {
        track.progress = payload.progress;
      } else {
        state.progressTracks.push(payload);
      }
    },
    removeProgressByFilename(state, name: string) {
      state.progressTracks = state.progressTracks.filter(
        (pt) => pt.name !== name
      );
    },
  },
  actions: {
    loadProjectList({ commit }) {
      return api.projects.list().then((projects) => {
        commit("setProjects", projects);
      });
    },
    loadCurrent({ commit, dispatch }, projectId) {
      const projectLoader = api.projects.get(projectId).then((project) => {
        commit("setCurrent", project);
      });
      const tagsLoader = dispatch("loadTags");
      return Promise.all([projectLoader, tagsLoader]);
    },
    loadTags({ commit }) {
      return api.tags.list().then((tags) => {
        commit("setTags", tags);
        return tags;
      });
    },
    createMix({ state, commit }) {
      if (!state.current)
        throw Error("Runtime Error: project is not loaded, can't create mix");
      const project = state.current;
      return api.projects.createMix(project._id).then((mix) => {
        commit("addMixToProject", mix);
        return mix;
      });
    },
    async uploadFiles({ state, commit }, files: File[]) {
      if (!state.current)
        throw Error("Runtime Error: can't upload track, project is not loaded");
      const projectId = state.current._id;
      const promises: Promise<void>[] = [];
      files.forEach((file) => {
        commit("updateProgressByFilename", {
          name: file.name,
          progress: 0,
        });
        const promise = api.projects
          .upload(projectId, file, (event) => {
            const completed = Math.round((event.loaded * 100) / event.total);
            commit("updateProgressByFilename", {
              name: file.name,
              progress: completed,
            });
          })
          .then((tracks) => {
            commit("addTracks", tracks);
          })
          .finally(() => {
            commit("removeProgressByFilename", file.name);
          });
        promises.push(promise);
      });
      await Promise.all(promises);
    },
    async deleteTrack({ commit }, track: Track) {
      return api.tracks.delete(track.filehash).then((track) => {
        commit("removeTrack", track);
      });
    },
    updateTrackTag({ commit }, payload: { filehash: string; tagId: string }) {
      return api.tracks
        .update(payload.filehash, { tag_id: payload.tagId })
        .then(() => {
          commit("setTrackTag", payload);
        });
    },
    destroyProject(_, projectId: string) {
      return api.projects.destroy(projectId);
    },
    updateProject({ state, commit }, payload: Partial<Project>) {
      const project = state.current;
      if (!project) {
        throw Error("Can't update project: project is not loaded");
      }
      return api.projects.update(project._id, payload).then((project) => {
        commit("setCurrent", project);
      });
    },
  },
};

export default module;
