import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-form" }
const _hoisted_2 = { class: "modal-form-header" }
const _hoisted_3 = { class: "modal-form-main" }
const _hoisted_4 = { class: "modal-form-description" }
const _hoisted_5 = { class: "modal-form-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.description), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onOk && _ctx.onOk(...args)), ["prevent","stop"])),
              class: "btn-outline"
            }, _toDisplayString(_ctx.okLabel), 1),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)), ["prevent","stop"])),
              class: "btn-outline"
            }, _toDisplayString(_ctx.cancelLabel), 1)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}