
import { Tag } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";

@Options({
  components: {
    ChevronDownIcon,
  },
  props: {
    tag: {
      type: Object,
    },
  },
  computed: {
    ...mapState("projects", ["tags"]),
  },
  data() {
    return {
      open: false,
    };
  },
})
export default class TagPicker extends Vue {
  open!: boolean;
  tag?: Tag;
  tags!: Record<string, Tag>;

  get tagList() {
    const tl = Object.values(this.tags);
    tl.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return tl;
  }

  get label() {
    return this.tag ? this.tag.name : "Pick a tag";
  }

  mounted(): void {
    window.addEventListener("mousedown", this.onMouseDown);
  }

  beforeUnmount(): void {
    window.removeEventListener("mousedown", this.onMouseDown);
  }

  onMouseDown(e: MouseEvent) {
    if (!this.open) return;
    const picker = this.$refs.picker as HTMLElement;
    let node: HTMLElement | null = e.target as HTMLElement;
    while (node) {
      if (node === picker) {
        return;
      }
      node = node.parentElement;
    }
    this.open = false;
  }

  pick(tag: Tag) {
    this.open = false;
    this.$emit("pick", tag);
  }
}
