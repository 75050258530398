
import { Tag, Track } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import { TrashIcon } from "@heroicons/vue/24/outline";
import TagPicker from "./TagPicker.vue";

@Options({
  components: {
    TrashIcon,
    TagPicker,
  },
  props: {
    track: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState("projects", ["tags"]),
  },
})
export default class TrackListItem extends Vue {
  track!: Track;
  tags!: Record<string, Tag>;

  get style() {
    const backgroundColor = this.tag ? `#${this.tag.color}70` : "#ffffff70";
    return {
      backgroundColor,
    };
  }

  get tag() {
    return this.tags[this.track.tag_id];
  }

  onPickTag(tag: Tag) {
    this.$store.dispatch("projects/updateTrackTag", {
      filehash: this.track.filehash,
      tagId: tag._id,
    });
  }
}
