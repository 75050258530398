import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cloud_arrow_down_icon = _resolveComponent("cloud-arrow-down-icon")!

  return (_openBlock(), _createElementBlock("div", {
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDownloadLink = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDownloadLink = false)),
    class: "track-waveform",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.showDownloadLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: _ctx.trackDownloadLink,
              class: "track-download",
              style: _normalizeStyle(_ctx.downloadLinkStyle)
            }, [
              _createVNode(_component_cloud_arrow_down_icon)
            ], 12, _hoisted_1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 36))
}