
import { Options, Vue } from "vue-class-component";
import { mapActions, mapMutations, mapState } from "vuex";
import { Band, User } from "@/models";
import Loading from "@/components/Loading.vue";
import Confirm from "@/components/Confirm.vue";
import UsersAutocomplete from "@/components/Autocomplete/UsersAutocomplete.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";
@Options({
  components: {
    Confirm,
    Loading,
    UsersAutocomplete,
    XMarkIcon,
  },
  data() {
    return {
      isLoading: true,
      deleteConfirm: false,
      name: "",
    };
  },
  methods: {
    ...mapMutations("bands", ["addMember", "removeMember"]),
    ...mapActions("bands", ["saveMembers"]),
  },
  computed: {
    ...mapState("bands", {
      band: "current",
    }),
  },
  watch: {
    "$route.params"() {
      if (this.$route.params.id) {
        this.reload();
      }
    },
  },
})
export default class BandView extends Vue {
  isLoading!: boolean;
  band!: Band;
  deleteConfirm!: boolean;

  name!: string;

  addMember!: (user: User) => void;
  removeMember!: (user: User) => void;
  saveMembers!: () => Promise<void>;

  reload() {
    this.isLoading = true;
    this.$store
      .dispatch("bands/loadCurrent", this.$route.params.id)
      .then(() => {
        this.isLoading = false;
        this.pullBandFields();
        document.title = `${this.band.name} - Eavesdrop`;
      });
  }

  mounted() {
    this.reload();
  }

  destroyBand() {
    console.log("destroy band");
    this.$store.dispatch("bands/destroyBand", this.band._id).then(() => {
      this.$router.replace("/projects");
    });
  }

  pullBandFields() {
    this.name = this.band.name;
  }

  saveSettings() {
    this.$store.dispatch("bands/updateBand", { name: this.name }).then(() => {
      this.pullBandFields();
    });
  }

  get members() {
    return this.band.members.filter((user) => user._id !== this.band.owner._id);
  }
}
