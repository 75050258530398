import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fake-drop track-mixer-drop"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_track_mixer = _resolveComponent("track-mixer")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ height: `${_ctx.height}px` }),
    class: _normalizeClass(["mix-view-mixers", { 'is-dragging': _ctx.draggingId !== null }])
  }, [
    (_ctx.dropId === 'at_start')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mixTracks, (mixTrack) => {
      return (_openBlock(), _createBlock(_component_track_mixer, {
        key: mixTrack._id,
        "mix-track": mixTrack,
        onMousedown: ($event: any) => (_ctx.onInitialMouseDown($event, mixTrack._id)),
        style: _normalizeStyle(_ctx.dragStyle),
        "to-delete": mixTrack._id === _ctx.draggingId && _ctx.deleteTrack,
        class: _normalizeClass({
        'track-mixer-drag': mixTrack._id === _ctx.draggingId,
        'track-mixer-drop': mixTrack._id === _ctx.dropId,
      })
      }, null, 8, ["mix-track", "onMousedown", "style", "to-delete", "class"]))
    }), 128))
  ], 6))
}