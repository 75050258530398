import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "picker",
  class: "picker"
}
const _hoisted_2 = {
  key: 0,
  class: "picker-options"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = !_ctx.open)),
      class: "picker-input"
    }, [
      _createTextVNode(_toDisplayString(_ctx.pickedLabel) + " ", 1),
      _createVNode(_component_chevron_down_icon)
    ]),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "picker-options-item",
              onClick: ($event: any) => (_ctx.pick(option)),
              key: option[_ctx.keyField]
            }, _toDisplayString(option[_ctx.labelField]), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 512))
}