
import { Tag, Track } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import TrackPickerSection from "./TrackPickerSection.vue";
import { PlusIcon } from "@heroicons/vue/24/solid";

const UNCATEGORIZED = "Other";

@Options({
  components: {
    TrackPickerSection,
    PlusIcon,
  },
  computed: {
    ...mapState("projects", { tagIndex: "tags" }),
    ...mapGetters("projects", ["tracksNotInUse"]),
  },
})
export default class TrackPicker extends Vue {
  tagIndex!: Record<string, Tag>;
  tracksNotInUse!: Track[];

  get tags() {
    const tags: Record<string, Tag> = {};
    this.tracksNotInUse.forEach((track) => {
      const tag = this.tagIndex[track.tag_id];
      if (tag) {
        tags[tag.name] = tag;
      }
    });
    const tagList = Object.values(tags);
    tagList.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    return tagList;
  }

  get tracks() {
    const structure: Record<string, Track[]> = {};
    this.tracksNotInUse.forEach((track) => {
      const tag = this.tagIndex[track.tag_id]?.name || UNCATEGORIZED;
      if (!(tag in structure)) {
        structure[tag] = [];
      }
      structure[tag].push(track);
    });
    return structure;
  }

  get hasUncategorized() {
    const uncatTracks = this.tracks[UNCATEGORIZED] || [];
    return uncatTracks.length > 0;
  }

  get tagUncategorized(): Tag {
    return {
      _id: "nocat",
      name: UNCATEGORIZED,
      color: "FFFFFF",
    };
  }

  onAddTrack(track: Track) {
    this.$emit("add", track);
  }
}
