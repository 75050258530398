
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";
import MainMenu from "./components/MainMenu.vue";
import { AuthState } from "./store";
import Loading from "./components/Loading.vue";
import Layout from "./views/Layout.vue";
import Authenticate from "./views/Authenticate.vue";
import AlertBox from "./components/AlertBox.vue";

@Options({
  components: {
    MainMenu,
    Loading,
    Layout,
    Authenticate,
    AlertBox,
  },
  computed: {
    ...mapState(["authState"]),
  },
})
export default class App extends Vue {
  authState!: AuthState;
  globalSelectDisable!: boolean;

  get isLoading() {
    return this.authState === AuthState.Authenticating;
  }

  get notLoggedIn() {
    return this.authState === AuthState.NotLoggedIn;
  }

  mounted() {
    this.$store.dispatch("loadAuthInfo");
  }
}
