
import { Options, Vue } from "vue-class-component";
import MainMenu from "@/components/MainMenu.vue";
import Loading from "../components/Loading.vue";
import { mapState } from "vuex";

@Options({
  components: {
    MainMenu,
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["globalSelectDisable"]),
  },
})
export default class Layout extends Vue {
  isLoading!: boolean;
  globalSelectDisable!: boolean;

  mounted() {
    this.reload();
  }

  reload() {
    this.isLoading = true;
    Promise.all([
      this.$store.dispatch("projects/loadProjectList"),
      this.$store.dispatch("bands/loadBandList"),
    ]).finally(() => {
      this.isLoading = false;
    });
  }
}
