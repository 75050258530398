import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "pan",
  title: "Channel panning"
}
const _hoisted_2 = {
  class: "width",
  title: "Channel width"
}
const _hoisted_3 = { class: "volume" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "delete"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_meter = _resolveComponent("audio-meter")!
  const _component_knob = _resolveComponent("knob")!
  const _component_trash_icon = _resolveComponent("trash-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["track-mixer", { 'track-mixer-muted': _ctx.mixTrack.actualMute }]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_component_audio_meter, {
      class: "name",
      value: _ctx.meter
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.track.name), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMute && _ctx.toggleMute(...args))),
      class: _normalizeClass(["btn mute", { 'mute-selected': _ctx.mixTrack.muted }]),
      title: "Mute channel"
    }, " M ", 2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSolo && _ctx.toggleSolo(...args))),
      class: _normalizeClass(["btn solo", { 'solo-selected': _ctx.mixTrack.solo }]),
      title: "Solo channel"
    }, " S ", 2),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_knob, {
        value: _ctx.mixTrack.pan,
        onInput: _ctx.onPanChange
      }, null, 8, ["value", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_knob, {
        value: _ctx.mixTrack.width,
        min: 0,
        max: 1,
        reset: 1,
        "is-width": true,
        onInput: _ctx.onWidthChange
      }, null, 8, ["value", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        type: "range",
        min: "0",
        max: "130",
        value: _ctx.volume,
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onVolumeChange && _ctx.onVolumeChange(...args)))
      }, null, 40, _hoisted_4)
    ]),
    (_ctx.toDelete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_trash_icon)
        ]))
      : _createCommentVNode("", true)
  ], 6))
}