import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "project-manager-project" }
const _hoisted_2 = { class: "project-manager-project-row" }
const _hoisted_3 = { class: "project-name" }
const _hoisted_4 = { class: "project-author" }
const _hoisted_5 = { class: "project-updated" }
const _hoisted_6 = { class: "project-mixes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_project_manager_mix = _resolveComponent("project-manager-mix")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/projects/${_ctx.project._id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.project.name), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _createElementVNode("div", _hoisted_4, " Submitted by " + _toDisplayString(_ctx.project.author.first_name) + " " + _toDisplayString(_ctx.project.author.last_name), 1),
      _createElementVNode("div", _hoisted_5, "updated " + _toDisplayString(_ctx.when), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.mixes, (mix) => {
        return (_openBlock(), _createBlock(_component_project_manager_mix, {
          key: mix._id,
          mix: mix,
          project: _ctx.project
        }, null, 8, ["mix", "project"]))
      }), 128))
    ])
  ]))
}