import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "meter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "meter-value",
      style: _normalizeStyle({ width: _ctx.valuePrc })
    }, null, 4),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}