import api from "@/api";
import { Band, User } from "@/models";
import { Module } from "vuex";
import { RootState } from ".";

export interface State {
  bands: Band[];
  current: Band | null;
}

const module: Module<State, RootState> = {
  namespaced: true,
  state: {
    bands: [],
    current: null,
  },
  mutations: {
    setBands(state, bands: Band[]) {
      state.bands = bands.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    },
    setCurrent(state, band: Band | null) {
      state.current = band;
    },
    addMember(state, user: User) {
      if (state.current) {
        if (user._id === state.current._id) {
          return;
        }
        if (state.current.members.some((member) => member._id === user._id))
          return;
        state.current.members.push(user);
      }
    },
    removeMember(state, user: User) {
      if (state.current) {
        state.current.members = state.current.members.filter(
          (member) => member._id !== user._id
        );
      }
    },
  },
  actions: {
    async loadBandList({ commit }) {
      const bands = await api.bands.list();
      commit("setBands", bands);
    },
    async loadCurrent({ commit }, bandId: string) {
      return api.bands.get(bandId).then((band) => {
        commit("setCurrent", band);
      });
    },
    destroyBand(_, bandId: string) {
      return api.bands.destroy(bandId);
    },
    updateBand({ state, commit }, payload: Partial<Band>) {
      const band = state.current;
      if (!band) {
        throw Error("Can't update band: band is not loaded");
      }
      return api.bands.update(band._id, payload).then((band) => {
        commit("setCurrent", band);
      });
    },
    async saveMembers({ state, commit }) {
      const band = state.current;
      if (!band) throw Error("Can't update band: band is not loaded");
      const memberIds = band.members
        .map((member) => member._id)
        .filter((memberId) => memberId !== band.owner._id);
      return api.bands.setMembers(band._id, memberIds).then((band) => {
        commit("setCurrent", band);
      });
    },
  },
};

export default module;
