import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tag-picker" }
const _hoisted_2 = {
  key: 0,
  ref: "picker",
  class: "tag-picker-picker"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_down_icon = _resolveComponent("chevron-down-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tagList, (ptag) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "tag-picker-option",
                  key: ptag._id
                }, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["tag-picker-option-link", { active: ptag._id === _ctx.tag?._id }]),
                    href: "#",
                    onClick: _withModifiers(($event: any) => (_ctx.pick(ptag)), ["prevent","stop"])
                  }, _toDisplayString(ptag.name), 11, _hoisted_3)
                ]))
              }), 128))
            ], 512))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("a", {
      class: "handler",
      href: "#",
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.open = true), ["prevent","stop"]))
    }, [
      _createVNode(_component_chevron_down_icon),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ])
  ]))
}