
import Modal from "./Modal.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    Modal,
  },
  emits: ["ok", "click"],
  props: {
    title: {
      type: String,
      default: "Confirm",
    },
    description: {
      type: String,
      default: "Are you sure?",
    },
    okLabel: {
      type: String,
      default: "Ok",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
  },
})
export default class Confirm extends Vue {
  title!: string;
  description!: string;
  okLabel!: string;
  cancelLabel!: string;

  onOk() {
    this.$emit("click");
    this.$emit("ok");
  }
  onCancel() {
    this.$emit("click");
    this.$emit("cancel");
  }

  mounted() {
    this.$emit("open");
  }
}
