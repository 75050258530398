import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "track-picker" }
const _hoisted_2 = {
  key: 0,
  class: "add-all"
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_track_picker_section = _resolveComponent("track-picker-section")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tracksNotInUse.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add-all'))),
            class: "btn-outline"
          }, " Add All Tracks ")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "No tracks to add")),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
      return (_openBlock(), _createBlock(_component_track_picker_section, {
        key: tag._id,
        tag: tag,
        tracks: _ctx.tracks[tag.name],
        onAdd: _ctx.onAddTrack
      }, null, 8, ["tag", "tracks", "onAdd"]))
    }), 128)),
    (_ctx.hasUncategorized)
      ? (_openBlock(), _createBlock(_component_track_picker_section, {
          key: 2,
          tag: _ctx.tagUncategorized,
          tracks: _ctx.tracks[_ctx.tagUncategorized.name],
          onAdd: _ctx.onAddTrack
        }, null, 8, ["tag", "tracks", "onAdd"]))
      : _createCommentVNode("", true)
  ]))
}