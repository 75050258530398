import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import MixView from "@/views/MixView.vue";
import BandView from "@/views/BandView.vue";
import ProjectView from "@/views/ProjectView.vue";
import ProjectManager from "@/views/ProjectManager.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/mixes/:id",
    name: "mix_view",
    component: MixView,
    meta: {
      section: "mix",
    },
  },
  {
    path: "/projects",
    name: "project_manager",
    component: ProjectManager,
    meta: {
      title: "Project Manager",
    },
  },
  {
    path: "/projects/:id",
    name: "project_view",
    component: ProjectView,
  },
  {
    path: "/bands/:id",
    name: "band_view",
    component: BandView,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/projects",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = `${to.meta.title} - Eavesdrop`;
  }
});

export default router;
