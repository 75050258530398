
import { WAVEFORM_PIXEL_PER_SEC } from "@/misc";
import { Mix } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapState } from "vuex";

@Options({
  computed: {
    ...mapState("mixes", ["position", "mix", "trackHeight"]),
  },
})
export default class TrackPositionPointer extends Vue {
  position!: number;
  trackHeight!: number;
  mix!: Mix | null;

  get style() {
    const mtcount = this.mix?.mix_tracks?.length || 0;
    const height = this.trackHeight * mtcount;
    const left = (this.position / 1000) * WAVEFORM_PIXEL_PER_SEC;
    return { left: `${left}px`, height: `${height}px` };
  }
}
