
import { WAVEFORM_SAMPLERATE, WAVEFORM_SEC_PER_PIXEL } from "@/misc";
import { MixTrack, Tag, Track } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { CloudArrowDownIcon } from "@heroicons/vue/24/outline";

@Options({
  components: {
    CloudArrowDownIcon,
  },
  props: {
    mixTrack: {
      type: Object,
      required: true,
    },
    scrollPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("projects", ["trackMap"]),
    ...mapState("projects", ["tags"]),
    ...mapState("mixes", ["trackHeight"]),
  },
  data() {
    return {
      showDownloadLink: false,
    };
  },
})
export default class TrackWaveform extends Vue {
  mixTrack!: MixTrack;
  trackMap!: Record<string, Track>;
  tags!: Record<string, Tag>;
  trackHeight!: number;
  showDownloadLink!: boolean;
  scrollPosition!: number;

  get track() {
    return this.trackMap[this.mixTrack.track_id];
  }

  get trackDownloadLink() {
    return `/api/v1/tracks/${this.track.filehash}?download=true`;
  }

  get waveformPath() {
    return `/api/v1/tracks/${this.track.filehash}/waveform/${this.track.waveform_ts}`;
  }

  get waveformWidth() {
    const trackLen = this.track.num_samples / WAVEFORM_SAMPLERATE; // in secs
    return trackLen / WAVEFORM_SEC_PER_PIXEL;
  }

  get trackTag() {
    return this.tags[this.track.tag_id];
  }

  get style() {
    const backgroundColor = this.trackTag
      ? `#${this.trackTag.color}30`
      : "#ffffff30";
    return {
      backgroundImage: `url(${this.waveformPath})`,
      backgroundColor,
      width: `${this.waveformWidth}px`,
      height: `${this.trackHeight}px`,
      backgroundSize: "100% 50px",
    };
  }

  get downloadLinkStyle() {
    return {
      left: `${4 + this.scrollPosition}px`,
    };
  }
}
