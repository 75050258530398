
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Authenticate extends Vue {
  get baseHost() {
    return process.env.NODE_ENV == "development"
      ? "http://localhost:8080"
      : "https://eavesdrop.narn.me";
  }

  get yandexLink() {
    return `https://oauth.yandex.ru/authorize?response_type=code&client_id=2c398cc13b194556bd5c1ede26eca319&redirect_uri=${this.baseHost}/api/v1/account/callback&state=${this.$route.fullPath}`;
  }

  get googleLink() {
    return `/api/v1/account/google_auth?baseHost=${this.baseHost}&redirect_to=${this.$route.fullPath}`;
  }
}
