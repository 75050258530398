
import { Track } from "@/models";
import { MixTrackExt } from "@/store/mixes";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters, mapState } from "vuex";
import AudioMeter from "./AudioMeter.vue";
import Knob from "./Knob.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";

@Options({
  components: {
    AudioMeter,
    TrashIcon,
    Knob,
  },
  props: {
    mixTrack: {
      type: Object,
      required: true,
    },
    toDelete: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions("mixes", ["patchMixTrack"]),
  },
  computed: {
    ...mapGetters("projects", ["trackMap"]),
    ...mapState("mixes", ["trackHeight"]),
  },
})
export default class TrackMixer extends Vue {
  mixTrack!: MixTrackExt;
  trackMap!: Record<string, Track>;
  trackHeight!: number;
  toDelete!: boolean;

  patchMixTrack!: (data: {
    mixTrackId: string;
    payload: Partial<MixTrackExt>;
  }) => Promise<void>;

  get track() {
    return this.trackMap[this.mixTrack.track_id];
  }

  get style() {
    return { height: `${this.trackHeight}px` };
  }

  get meter() {
    return this.mixTrack.channel?.meter || 0;
  }

  get volume() {
    return this.mixTrack.volume * 100;
  }

  adjustMixTrackValue<K extends keyof MixTrackExt>(
    key: K,
    value: MixTrackExt[K]
  ) {
    const payload = { [key]: value };
    const mixTrackId = this.mixTrack._id;
    this.patchMixTrack({ mixTrackId, payload });
  }

  onVolumeChange(e: Event) {
    const target = e.target as HTMLInputElement;
    const value = target.valueAsNumber;
    this.adjustMixTrackValue("volume", value / 100);
  }

  onPanChange(pan: number) {
    this.adjustMixTrackValue("pan", pan);
  }

  onWidthChange(width: number) {
    this.adjustMixTrackValue("width", width);
  }

  toggleMute() {
    this.adjustMixTrackValue("muted", !this.mixTrack.muted);
  }
  toggleSolo() {
    this.adjustMixTrackValue("solo", !this.mixTrack.solo);
  }
}
