
import {
  formatTime,
  WAVEFORM_PIXEL_PER_SEC,
  WAVEFORM_SAMPLERATE,
} from "@/misc";
import { Mix } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapGetters, mapState } from "vuex";
import { scaleLinear } from "d3";
import { TimelineType } from "@/views/MixView.vue";

@Options({
  props: {
    top: {
      type: Number,
      default: 0,
    },
    timelineType: {
      type: String,
      default: "time",
    },
  },
  computed: {
    ...mapGetters("projects", ["maxNumSamples"]),
    ...mapState("mixes", ["mix"]),
  },
})
export default class TrackTimeline extends Vue {
  maxNumSamples!: number;
  mix!: Mix;
  top!: number;
  timelineType!: TimelineType;

  get trackLengthSec() {
    return this.maxNumSamples / WAVEFORM_SAMPLERATE; // Track length in sec
  }

  get trackLengthPx() {
    return this.trackLengthSec * WAVEFORM_PIXEL_PER_SEC;
  }

  get ticks() {
    const tempo = this.mix.tempo;
    const beats = (this.trackLengthSec / 60) * tempo;
    const measures = beats / 4;
    const scale = scaleLinear()
      .range([0, this.trackLengthSec])
      .domain([0, measures]);
    const ticks = [];
    for (let m = 0; m < measures; m++) {
      const sec = scale(m);
      ticks.push({
        left: WAVEFORM_PIXEL_PER_SEC * sec,
        label: this.timelineType === "time" ? formatTime(sec) : m + 1,
      });
    }
    return ticks;
  }
}
