import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "alerts-overlay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_box_item = _resolveComponent("alert-box-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
      return (_openBlock(), _createBlock(_component_alert_box_item, {
        key: message.id,
        message: message
      }, null, 8, ["message"]))
    }), 128))
  ]))
}