
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    value: {
      required: true,
      type: Number,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
})
export default class AudioMeter extends Vue {
  value!: number;
  max!: number;
  min!: number;

  get valuePrc() {
    let prc = Math.round(
      (100 * (this.value - this.min)) / (this.max - this.min)
    );
    if (prc < 0) prc = 0;
    if (prc > 100) prc = 100;
    return `${100 - prc}%`;
  }
}
