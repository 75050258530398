
import { Band, Mix, Project, User } from "@/models";
import { Options, Vue } from "vue-class-component";
import { mapActions, mapState } from "vuex";
import SubMenu from "./SubMenu.vue";
import SubMenuItem from "./SubMenuItem.vue";
import Confirm from "./Confirm.vue";
import Modal from "./Modal.vue";
import BandsAutocomplete from "./Autocomplete/BandsAutocomplete.vue";
import api from "@/api";
import BounceModal from "./BounceModal.vue";

@Options({
  components: {
    Confirm,
    Modal,
    SubMenu,
    SubMenuItem,
    BandsAutocomplete,
    BounceModal,
  },
  data() {
    return {
      menu: "eavesdrop",
      menuOpened: false,
      cloneConfirm: false,
      deleteConfirm: false,
      newProjectForm: false,
      projectBand: null,
      projectName: "",
      newBandForm: false,
      bounceForm: false,
      bandName: "",
    };
  },
  computed: {
    ...mapState("bands", ["bands"]),
    ...mapState("projects", { project: "current", projects: "projects" }),
    ...mapState("mixes", ["mixPropsDirty", "mix"]),
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
  },
})
export default class MainMenu extends Vue {
  menu!: string;
  menuOpened!: boolean;
  projects!: Project[];
  project!: Project;
  bands!: Band[];
  mixPropsDirty!: boolean;

  cloneConfirm!: boolean;
  deleteConfirm!: boolean;
  newProjectForm!: boolean;
  newBandForm!: boolean;
  bounceForm!: boolean;

  projectName!: string;
  projectBand!: Band | null;

  bandName!: string;
  user!: User;
  mix!: Mix | null;

  logout!: () => Promise<void>;

  saveMix() {
    this.$store.dispatch("mixes/saveMix");
    this.menuOpened = false;
  }

  get stemsLink() {
    if (!this.mix) return "";
    return `/api/v1/mixes/${this.mix._id}/download`;
  }

  get projectsSlice() {
    return this.projects.slice(0, 10);
  }

  mounted() {
    window.addEventListener("click", this.onClickOutside);
  }

  beforeUnmount() {
    window.removeEventListener("click", this.onClickOutside);
  }

  onMenuClick(e: MouseEvent) {
    let node = e.target as HTMLElement | null;
    while (node) {
      if (node.classList?.contains("mainmenu-item")) {
        this.menuOpened = true;
        return;
      }
      node = node.parentNode as HTMLElement | null;
    }
    this.menuOpened = false;
  }

  onClickOutside(e: MouseEvent) {
    let node = e.target as HTMLElement | null;
    while (node) {
      if (node === this.$refs.mainmenu) {
        return;
      }
      node = node.parentNode as HTMLElement | null;
    }
    this.menuOpened = false;
  }

  async cloneMix() {
    const mix: Mix = await this.$store.dispatch("mixes/cloneMix");
    this.$router.push(`/mixes/${mix._id}`);
  }

  deleteMix() {
    this.$store.dispatch("mixes/deleteMix").then(() => {
      this.$router.push(`/projects/${this.project._id}`);
    });
  }

  openBounceForm() {
    this.bounceForm = true;
    this.menuOpened = false;
  }

  createMix() {
    this.$store.dispatch("projects/createMix").then((mix) => {
      this.$router.push(`/mixes/${mix._id}`);
    });
  }

  openNewProjectForm() {
    this.newProjectForm = true;
    this.menuOpened = false;
    this.$nextTick(() => {
      const input = this.$refs.projectNameInput as HTMLElement;
      input.focus();
    });
  }

  openNewBandForm() {
    this.newBandForm = true;
    this.menuOpened = false;
    this.$nextTick(() => {
      const input = this.$refs.bandNameInput as HTMLElement;
      input.focus();
    });
  }

  async createProject() {
    if (this.projectBand === null) {
      this.$store.dispatch("messages/error", "You must pick a band");
      return;
    }
    const project = await api.projects.create({
      name: this.projectName,
      band_id: this.projectBand._id,
    });
    this.newProjectForm = false;
    this.$router.push(`/projects/${project._id}`);
    this.$store.dispatch("projects/loadProjectList");
  }

  async createBand() {
    const band = await api.bands.create({
      name: this.bandName,
    });
    this.newBandForm = false;
    this.$router.push(`/bands/${band._id}`);
    this.$store.dispatch("bands/loadBandList");
  }
}
