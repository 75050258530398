import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "project-view-progress-track-list-item" }
const _hoisted_2 = { class: "track-name" }
const _hoisted_3 = { class: "track-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.track.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "track-progress-prog",
        style: _normalizeStyle(_ctx.progStyle)
      }, null, 4)
    ])
  ]))
}