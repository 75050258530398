import axios, { AxiosResponse } from "axios";
import store from "@/store";
import { Band, Mix, MixTrack, Project, Tag, Track, User } from "./models";

function wrap<T>(
  axiosResponse: Promise<AxiosResponse>,
  dataProp = "data"
): Promise<T> {
  return axiosResponse
    .then((resp) =>
      dataProp !== "" ? (resp.data[dataProp] as T) : (resp.data as T)
    )
    .catch((err) => {
      if (err?.response?.status === 401) {
        throw err;
      }

      const errorMessage = err?.response?.data?.error;
      if (errorMessage) {
        store.dispatch("messages/error", errorMessage);
      } else {
        store.dispatch(
          "messages/error",
          `Server responded with status code ${err?.response?.status}, the response was ${err?.response?.data}`
        );
      }
      throw err;
    });
}

export default {
  account: {
    me() {
      return wrap<User>(axios.get("/api/v1/account/me"));
    },
    logout() {
      return wrap<never>(axios.post("/api/v1/account/logout"));
    },
  },
  bands: {
    list() {
      return wrap<Band[]>(axios.get("/api/v1/bands/?_nopaging=true"));
    },
    get(bandId: string) {
      return wrap<Band>(axios.get(`/api/v1/bands/${bandId}`));
    },
    update(bandId: string, payload: Partial<Band> | { member_ids: string[] }) {
      return wrap<Band>(axios.patch(`/api/v1/bands/${bandId}`, payload));
    },
    create(payload: Partial<Band>) {
      return wrap<Band>(axios.post("/api/v1/bands/", payload));
    },
    destroy(bandId: string) {
      return wrap<Band>(axios.delete(`/api/v1/bands/${bandId}`));
    },
    setMembers(bandId: string, memberIds: string[]) {
      return wrap<Band>(
        axios.put(`/api/v1/bands/${bandId}/members`, {
          member_ids: memberIds,
        })
      );
    },
  },
  projects: {
    list() {
      return wrap<Project[]>(axios.get("/api/v1/projects/?_nopaging=true"));
    },
    get(projectId: string) {
      return wrap<Project>(axios.get(`/api/v1/projects/${projectId}`));
    },
    update(projectId: string, payload: Partial<Project>) {
      return wrap<Project>(
        axios.patch(`/api/v1/projects/${projectId}`, payload)
      );
    },
    create(payload: { name: string; band_id: string; tempo?: number }) {
      return wrap<Project>(axios.post("/api/v1/projects/", payload));
    },
    destroy(projectId: string) {
      return wrap<Project>(axios.delete(`/api/v1/projects/${projectId}`));
    },
    upload(
      projectId: string,
      file: File,
      onProgress: (e: ProgressEvent) => void
    ) {
      const form = new FormData();
      form.append("tracks", file);
      return wrap<Track[]>(
        axios.post(`/api/v1/projects/${projectId}/tracks`, form, {
          onUploadProgress: onProgress,
        })
      );
    },
    listMixes(projectId: string) {
      return wrap<Mix[]>(axios.get(`/api/v1/projects/${projectId}/mixes`));
    },
    createMix(projectId: string) {
      return wrap<Mix>(axios.post(`/api/v1/projects/${projectId}/mixes`));
    },
  },
  mixes: {
    get(mixId: string) {
      return wrap<Mix>(axios.get(`/api/v1/mixes/${mixId}`));
    },
    update(mixId: string, payload: Partial<Mix>) {
      return wrap<Mix>(axios.patch(`/api/v1/mixes/${mixId}`, payload));
    },
    bulkUpdate(mixId: string, mixTracks: MixTrack[]) {
      return wrap<MixTrack[]>(
        axios.put(`/api/v1/mixes/${mixId}/mix_tracks`, {
          mix_tracks: mixTracks,
        })
      );
    },
    clone(mixId: string) {
      return wrap<Mix>(axios.post(`/api/v1/mixes/${mixId}/clone`));
    },
    delete(mixId: string) {
      return wrap<Mix>(axios.delete(`/api/v1/mixes/${mixId}`));
    },
    bounce(mixId: string, options: Record<string, string>) {
      let url = `/api/v1/mixes/${mixId}/bounce`;
      if (Object.keys(options).length) {
        const qs = new URLSearchParams(options);
        url += "?" + qs.toString();
      }
      window.open(url);
    },
  },
  mixTracks: {
    create(mixId: string, trackId: string) {
      return wrap<MixTrack>(
        axios.post(`/api/v1/mixes/${mixId}/mix_tracks`, {
          track_id: trackId,
        })
      );
    },
    update(mixId: string, mixTrackId: string, payload: Partial<MixTrack>) {
      return wrap<MixTrack>(
        axios.patch(`/api/v1/mixes/${mixId}/mix_tracks/${mixTrackId}`, payload)
      );
    },
    delete(mixId: string, mixTrackId: string) {
      return wrap<MixTrack>(
        axios.delete(`/api/v1/mixes/${mixId}/mix_tracks/${mixTrackId}`)
      );
    },
  },
  users: {
    search(query: string) {
      return wrap<User[]>(axios.get(`/api/v1/users/search?q=${query}`));
    },
  },
  tags: {
    list() {
      return wrap<Tag[]>(axios.get(`/api/v1/tags/`));
    },
  },
  tracks: {
    download(track: Track) {
      const path = `/api/v1/tracks/${track.filehash}`;
      return wrap<ArrayBuffer>(
        axios.get(path, { responseType: "arraybuffer" }),
        ""
      );
    },
    update(filehash: string, payload: Partial<Track>) {
      return wrap<Track>(axios.patch(`/api/v1/tracks/${filehash}`, payload));
    },
    delete(filehash: string) {
      return wrap<Track>(axios.delete(`/api/v1/tracks/${filehash}`));
    },
  },
};
