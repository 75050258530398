
import { Options, Vue } from "vue-class-component";
import Loading from "@/components/Loading.vue";
import { mapState } from "vuex";
import { Band, Project } from "@/models";
import ProjectManagerProject from "@/components/ProjectManagerProject.vue";

@Options({
  components: {
    Loading,
    ProjectManagerProject,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("projects", ["projects"]),
    ...mapState("bands", ["bands"]),
  },
})
export default class ProjectManager extends Vue {
  isLoading!: boolean;
  projects!: Project[];
  bands!: Band[];

  get structure(): {
    name: string;
    projects: Project[];
  }[] {
    return this.bands
      .map((band) => {
        const bandProjects = this.projects
          .filter((project) => project.band._id === band._id)
          .sort((a, b) => {
            const ad = new Date(a.updated_at).getTime();
            const bd = new Date(b.updated_at).getTime();
            if (ad > bd) return -1;
            if (ad < bd) return 1;
            return 0;
          });
        return {
          name: band.name,
          projects: bandProjects,
        };
      })
      .filter((obj) => obj.projects.length > 0);
  }

  reload() {
    this.isLoading = true;
    Promise.all([
      this.$store.dispatch("projects/loadProjectList"),
      this.$store.dispatch("bands/loadBandList"),
    ]).finally(() => {
      this.isLoading = false;
    });
  }

  mounted() {
    this.reload();
  }
}
